import {
  SET_ADMIN_WINDOW,
  SET_PROJECTS_LIST,
  RESET_PROJECTS_LIST,
  TOGGLE_PROJECT,
  TOGGLE_SINGLE_PROJECT,
  SET_HASHES,
  RESET_HASHES,
  PUSH_TO_HASHES,
  TOGGLE_SPECIAL_RIGHT,
  SET_REG_HASH,
  UNSELECT_ALL,
} from "./constants";

export const setAdminWindow = (window) => ({
  type: SET_ADMIN_WINDOW, window
})
export const setProjectsList = (list) => ({
  type: SET_PROJECTS_LIST, list
})
export const resetProjectsList = () => ({
  type: RESET_PROJECTS_LIST
})
export const toggleProject = (index) => ({
  type: TOGGLE_PROJECT, index
})
export const toggleSingleProject = (index) => ({
  type: TOGGLE_SINGLE_PROJECT, index
})
export const setHashes = (list) => ({
  type: SET_HASHES, list
})
export const resetHashes = () => ({
  type: RESET_HASHES
})
export const pushToHashes = (hash) => ({
  type: PUSH_TO_HASHES, hash
})
export const toggleSpecialRight = (idProject, idSpecial) => ({
  type: TOGGLE_SPECIAL_RIGHT, idProject, idSpecial
})
export const setRegHash = (hash) => ({
  type: SET_REG_HASH, hash
})
export const unselectAll = () => ({
  type: UNSELECT_ALL
})