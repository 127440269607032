import {
  SET_ADMIN_WINDOW,
  SET_PROJECTS_LIST,
  RESET_PROJECTS_LIST,
  TOGGLE_PROJECT,
  TOGGLE_SINGLE_PROJECT,
  SET_HASHES,
  RESET_HASHES,
  TOGGLE_SPECIAL_RIGHT,
  PUSH_TO_HASHES,
  SET_REG_HASH,
  UNSELECT_ALL,
} from "./constants";

const initState = {
  adminWindow: 'reg',
  projects: null,
  hashes: [],
  regHash: null,
}

const reducer = (state = initState, action) => {
  switch (action.type) {

    case SET_ADMIN_WINDOW: {
      return { ...state, adminWindow: action.window }
    }

    case SET_PROJECTS_LIST: {
      return { ...state, projects: action.list }
    }

    case RESET_PROJECTS_LIST: {
      let list = state.projects.slice()
      list.map( item => {
        item.isChecked = false;
        item.special.map( spec => {spec.isChecked = false; return null;} );
        return null
      } )
      return { ...state, projects: list }
    }

    case TOGGLE_PROJECT: {
      let list = state.projects.slice()
      list[action.index].isChecked = !list[action.index].isChecked
      return { ...state, projects: list }
    }

    case UNSELECT_ALL: {
      let list = state.projects.slice()
      list.map( item => {
        item.isChecked = false;
        item.special.map( spec => {spec.isChecked = false; return null;} );
        return null
      } )
      return { ...state, projects: list }
    }

    case TOGGLE_SINGLE_PROJECT: {
      let list = state.projects.slice()
      list.map( item => {
        item.isChecked = false;
        item.special.map( spec => {spec.isChecked = false; return null;} );
        return null
      } )
      list[action.index].isChecked = !list[action.index].isChecked
      return { ...state, projects: list }
    }

    case SET_HASHES: {
      return { ...state, hashes: action.list }
    }

    case RESET_HASHES: {
      return { ...state, hashes: [] }
    }

    case PUSH_TO_HASHES: {
      let list = state.hashes.slice()
      list.push(action.hash)
      return { ...state, hashes: list }
    }

    case TOGGLE_SPECIAL_RIGHT: {
      let list = state.projects.slice()
      let pId = action.idProject
      let sId = action.idSpecial
      // console.log(list[pId])
      list[pId].special[sId].isChecked = !list[pId].special[sId].isChecked
      return { ...state, projects: list }
    }

    case SET_REG_HASH: {
      return { ...state, regHash: action.hash }
    }



    default: {
      return state
    }
  }
}

export default reducer
